// extracted by mini-css-extract-plugin
export var box = "funcitonCase-module--box--ozyL1";
export var classify1 = "funcitonCase-module--classify1--RdVdz";
export var detail = "funcitonCase-module--detail--Ex2Is";
export var functioncase = "funcitonCase-module--functioncase--+Nk2T";
export var head = "funcitonCase-module--head--NHnuQ";
export var hover = "funcitonCase-module--hover--BRknp";
export var main = "funcitonCase-module--main--bBbvd";
export var name1 = "funcitonCase-module--name1--T44Qw";
export var noHover = "funcitonCase-module--noHover--dUxwU";
export var position1 = "funcitonCase-module--position1--QgcMP";
export var visible = "funcitonCase-module--visible--MqN+U";