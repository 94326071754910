import React from "react";

import("./projectTitle.scss");
export default function ProjectTitle({ title, subTitle }) {
    return (
        <div id="project-title">
            <h4>{subTitle}</h4>
            <h3>{title}</h3>
        </div>
    )
}