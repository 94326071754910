export const projectCaseList=[
    {title:'拉各斯火车站站房',postion:'NIGERIA LAGOS（尼日利亚，拉各斯）',type:'轨道交通及建筑',bgUrl:'/project/project-case-1.jpg',hoverUrl:'/icons/trademark-tumu.png',outsideLink:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247484046&idx=2&sn=099ecc7ddab744a0d06a44e67d0457ae&chksm=cf7084faf8070decdbe6ea9b4f88785e14120baee02a87ef5cfd1ff972147f05fcc6ea4a36d1&token=602324325&lang=zh_CN#rd'},
    {title:'厦门地铁六号线',postion:'福建厦门',type:'高速道路 / 桥梁隧道',bgUrl:'/project/project-case-2.jpg',hoverUrl:'/icons/trademark-jiaotong.png',outsideLink:'https://mp.weixin.qq.com/s/HWg7iVn2twHLFA8N_zVqhA'},
    {title:'郑州地铁12号线',postion:'河南郑州',type:'轨道交通及建筑',bgUrl:'/project/project-case-3.jpg',hoverUrl:'/icons/trademark-zhongtie.png',outsideLink:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247484434&idx=1&sn=a3a3344f57395b5d345c98b0e5ae0011&chksm=cf708266f8070b70d1512ad9231d6f839088850833b6e95be4c327856a799e3af382ba7c05e5&token=602324325&lang=zh_CN#rd'},
    {title:'重庆至黔江铁路站前9标桥梁工程',postion:'重庆',type:'桥梁',bgUrl:'/project/project-case-4.jpg',hoverUrl:'/icons/trademark-daqiao.png',outsideLink:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247491258&idx=1&sn=a6a869861271251c92b6f6f077a5be0b&chksm=cf7098cef80711d8bc04b75272d84b5a31eccf51c5c769be085f71420e80b9bc8c06a2652422&token=602324325&lang=zh_CN#rd'},
    {title:'福州鼓山马尾隧道提升改造工程',postion:'福建福州',type:'隧道',bgUrl:'/project/project-case-5.jpg',hoverUrl:'/icons/trademark-chengxiang.png',outsideLink:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247489833&idx=1&sn=e49f9299e79e29586b1c60c9ab32654f&chksm=cf709f5df807164b8cb90e46240e21ebd053db1d63e14b586cfe8608d5ac01d56ea8835d19a0&token=602324325&lang=zh_CN#rd'},
    {title:'赣深高铁信丰西站站房项目',postion:'贵州',type:'桥梁隧道',bgUrl:'/project/project-case-6.jpg',hoverUrl:'/icons/trademark-zhongtie.png',outsideLink:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247484046&idx=3&sn=3029709b235c8229b50ed4df6e912dc7&chksm=cf7084faf8070dec781fda696091f721b33c1a416ad5388f1863dc5aa05eb68a5777eb0c291b&token=602324325&lang=zh_CN#rd'},

    {title:'都安高速T28标段',postion:'都匀至安顺',type:'都匀至安顺',bgUrl:'/project/project-case-7.jpg',hoverUrl:'/icons/trademark-zhongtie.png',outsideLink:undefined},
    {title:'福州工业路提升改造工程',postion:'福建福州',type:'类型：城市道路 / 桥梁',bgUrl:'/project/project-case-8.jpg',hoverUrl:'/icons/trademark-zhongmei.png',outsideLink:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247491198&idx=1&sn=d5ec12f2c171477b8f0494836e90e3ae&chksm=cf70980af807111c9ff7723c0f2b7bf12cba7b3029bc671040d36285d981da03d9df290ad64d&token=602324325&lang=zh_CN#rd'},
    {title:'福州白湖亭立交工程',postion:'福建福州',type:'城市道路 / 桥梁',bgUrl:'/project/project-case-9.jpg',hoverUrl:'/icons/trademark-zhongmei.png',outsideLink:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247490270&idx=1&sn=75a355c4ead7ff19282c1e80ff083bdb&chksm=cf709caaf80715bc3dabb5656e06b9935870643b6e20c78136b658d2348b5873b304c83bdfe3&token=602324325&lang=zh_CN#rd'},
    {title:'沙坪坝铁路枢纽',postion:'重庆',type:'铁路交通枢纽 / TOD',bgUrl:'/project/project-case-10.jpg',hoverUrl:'/icons/trademark-zhongtie.png',outsideLink:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247490129&idx=3&sn=76a8906792f8ac59bc92c58bc45ba57d&chksm=cf709c25f80715336216e05b89bae920dfa642f220c7d13fe7907d2a33d1b0ace7005cfe8370&token=602324325&lang=zh_CN#rd'},
    {title:'厦门马銮湾综合管廊',postion:'福建厦门',type:'地下管廊',bgUrl:'/project/project-case-11.jpg',hoverUrl:'/icons/trademark-zhongtie.png',outsideLink:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247484145&idx=1&sn=ced0b7f1fa5264e864b2c646674de16a&chksm=cf708485f8070d93d290545978ab207faa9776e282ad36b259688ea0b4151ea94a1394a9cd5a&'},
    {title:'南京江北新区综合管廊PPP项目',postion:'江苏南京',type:'地下管廊',bgUrl:'/project/project-case-12.jpg',hoverUrl:'/icons/trademark-zhongtie.png',outsideLink:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247484145&idx=2&sn=75459eb64e6dd7fdc947b432596721ce&chksm=cf708485f8070d93e9f826927d48f7c15c53d0c8206f55349172160667e279b7098420cef6c1&token=602324325&lang=zh_CN#rd'},
]

export const buildCaseList=[
    {title:'中联重科新建厂房项目',postion:'',type:'工业厂房',bgUrl:'/project/build-case-1.jpg',hoverUrl:'/icons/trademark-tumu.png',outsideLink:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247490357&idx=2&sn=d23bf5e64200911e372b5f25421ae4f9&chksm=cf709d41f807145785308fdba50106a01847b1ba72b43a12cb9720014808032bb107b9df8fd5&token=602324325&lang=zh_CN#rd'},
    {title:'厦门地铁六号线',postion:'建福厦门',type:'综合园区',bgUrl:'/project/build-case-2.jpg',hoverUrl:'/icons/trademark-zhongmei.png',outsideLink:'https://mp.weixin.qq.com/s/HWg7iVn2twHLFA8N_zVqhA'},
    {title:'金牛山城市森林步道及景观工程',postion:'福建福州',type:'综合园区',bgUrl:'/project/build-case-3.jpg',hoverUrl:'/icons/trademark-tumu.png',outsideLink:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247484150&idx=2&sn=e0c2c4bc788ca2cef7258675763bf919&chksm=cf708482f8070d94d4d32c412c7cc274c46fa445f93f62c18488c46ea5a385295e434f6981e9&token=602324325&lang=zh_CN#rd'},
    {title:'抚仙湖养生度假中心项目',postion:'云南',type:'综合园区',bgUrl:'/project/build-case-4.jpg',hoverUrl:'/icons/trademark-lianjie.png',outsideLink:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247484150&idx=1&sn=43d24e512fea99da2104628667229844&chksm=cf708482f8070d94d51ca928a1242cf365f2a5d7fe41b7fbe809b00f357b0fc73bf7dbd20f41&token=602324325&lang=zh_CN#rd'},
    {title:'明溪县全民健身活动中心项目',postion:'',type:'综合园区',bgUrl:'/project/build-case-5.jpg',hoverUrl:'/icons/trademark-chengxiang.png',outsideLink:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247489358&idx=1&sn=6245b42ff809d4a17605d66c287ed68f&chksm=cf70913af807182ce932c5f87fc5c01a204aa836437477bd8dee7688a12f69b61acc6fd85011&token=602324325&lang=zh_CN#rd'},
]
export const modeInterList=[
    {title:'拉各斯火车站站房',postion:'NIGERIA LAGOS（尼日利亚，拉各斯）',type:'轨道交通及建筑',bgUrl:'',outsideLink:undefined},
    {title:'厦门地铁六号线',postion:'福建厦门',type:'轨道交通及建筑',bgUrl:'',outsideLink:undefined},
    {title:'郑州地铁12号线',postion:'河南郑州',type:'轨道交通及建筑',bgUrl:'',outsideLink:undefined},
    {title:'重庆至黔江铁路站前9标桥梁工程',postion:'重庆',type:'桥梁',bgUrl:'',outsideLink:undefined},
    {title:'福州鼓山马尾隧道提升改造工程',postion:'福建福州',type:'隧道',bgUrl:'',outsideLink:undefined},
    {title:'福州工业路提升改造工程',postion:'福建福州',type:'城市道路 / 桥梁',bgUrl:'',outsideLink:undefined},
]