//#region Rely
import React, { useState } from "react";

//#endregion

//#region component
import MainFrame from "../components/main-frame";
import MainMenu from "../components/main-menu";
import ProjectCase from "../components/widgets/projectCase";
import ProjectTitle from "../components/widgets/projectTitle";
import FunctionCase from "../components/widgets/functionCase";
//#endregion

//#region option
import {
    functionNav,
} from "../staticConfMange/navMange.js"
import {
    buildCaseList,
    projectCaseList,
} from "../staticConfMange/projectMessage.js"
import {lightweightList,vrList} from '../staticConfMange/functionMessage'
//#endregion

//#region cssRely
import {
    pageHeader,
    mainMenu,
} from "./index.module.css"

import {

    optionContainer
} from "./product.module.css"

import {
    main,
    functionCaseContainer
} from "./function.module.css"
//sass
//7/7sass的热重载会有问题，所以暂时还是不使用
// import("./project.scss");
//#endregion
/**
 * 
 * @returns {JSX.Element}
 */
export default function Porject({path}) {
    const [active, setActive] = useState(0);
    return (
        <MainFrame id="project-container">
            <header className={pageHeader}>
                <MainMenu className={mainMenu} navPorps={functionNav} active={active} path={path} setActive={setActive} />
                <img alt="" style={{ width: '100%' }} src={'/project/project-bg-2.jpg'} />
            </header>
            {/* 带有.main的都是一个frame，后期看看Gatsby是否有路由插槽这样的东西，没有就这样子算了。有就拆分成组件，然后再显示 */}
            <div className={main}>
                <div className={optionContainer} id='a'>
                    <ProjectTitle {...{ subTitle: "功能体验", title: '轻量化模型互动' }} />
                    <div className={functionCaseContainer}>
                          {
                            lightweightList.map(item=><FunctionCase key={item.name} href={item.href} img={item.img} name={item.name} position={item.position} classify={item.classify} />)
                          }
                    </div>
                </div>
                <div className={optionContainer} id='b'>
                    <ProjectTitle {...{ subTitle: "功能体验", title: '720-VR全景图' }} />
                    <div className={functionCaseContainer}>
                    {
                            vrList.map(item=><FunctionCase key={item.name} href={item.href} img={item.img} name={item.name} position={item.position} />)
                          }
                    </div>
                </div>
            </div>
            {/* <div className={main} style={{ display: active === 1 ? "block" : 'none' }}>

            </div> */}
        </MainFrame>
    )
}