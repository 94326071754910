import React from 'react'
import {
    name1,
    functioncase,
    head,
    main,
    visible,
    position1,
    classify1,
    noHover,
    box,
    hover,
    detail
} from './funcitonCase.module.css'

export default function functionCase({ img, name, position, classify, href }) {
    return (
        <div className={functioncase}>
            <div className={head}><img src={img} alt="" /></div>
            <div className={main}>
                <h4 className={name1}>{name}</h4>
                <p className={position1}>{position}</p>
                <p className={classify1}>{classify}</p>
                <div className={detail}>
                    <a href={href} target={'_blank'} >
                        <div className={visible}>
                            查看详情
                        </div>
                        <div className={box}>
                            <div className={hover}></div>

                            <div className={noHover}></div>
                        </div>
                    </a>

                </div>
            </div>
        </div>
    )
}
