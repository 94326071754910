export const lightweightList=[
    {img:'/funciton/function-q-1.png',name:'项目名称：拉各斯火车站站房',position:'位置：NIGERIA LAGOS（尼日利亚，拉各斯）',classify:'类型：轨道交通及建筑',href:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247484046&idx=2&sn=099ecc7ddab744a0d06a44e67d0457ae&chksm=cf7084faf8070decdbe6ea9b4f88785e14120baee02a87ef5cfd1ff972147f05fcc6ea4a36d1&token=602324325&lang=zh_CN#rd'},
    {img:'/funciton/function-q-2.png',name:'项目名称：厦门地铁六号线',position:'位置：福建厦门',classify:'类型：轨道交通及建筑',href:'http://qyjbim.com/bim/#/test/xmjh'},
    {img:'/funciton/function-q-3.png',name:'项目名称：郑州地铁12号线',position:'位置：河南郑州',classify:'类型：轨道交通及建筑',href:'http://qyjbim.com/bim/#/metro-line/main-page/zzm'},
    {img:'/funciton/function-q-4.png',name:'项目名称：重庆至黔江铁路站前9标桥梁工程',position:'位置：重庆',classify:'类型：桥梁',href:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247491258&idx=1&sn=a6a869861271251c92b6f6f077a5be0b&chksm=cf7098cef80711d8bc04b75272d84b5a31eccf51c5c769be085f71420e80b9bc8c06a2652422&token=602324325&lang=zh_CN#rd'},
    {img:'/funciton/function-q-5.png',name:'项目名称：福州鼓山马尾隧道提升改造工程',position:'位置：福建福州',classify:'类型：隧道',href:'https://mp.weixin.qq.com/s?__biz=Mzg4MDY2MTI3NQ==&mid=2247489833&idx=1&sn=e49f9299e79e29586b1c60c9ab32654f&chksm=cf709f5df807164b8cb90e46240e21ebd053db1d63e14b586cfe8608d5ac01d56ea8835d19a0&token=602324325&lang=zh_CN#rd'},
    {img:'/funciton/function-q-6.png',name:'项目名称：福州工业路提升改造工程',position:'位置：福建福州',classify:'类型：城市道路 / 桥梁',href:'http://qyjbim.com/bim/#/project/home/gyl-gj'},
]

export const vrList=[
    {img:'/funciton/function-vr-1.png',name:'类型：场地布置',position:'展示内容：郑州12号线圣佛寺站场地布置',href:'https://720yun.com/t/02vkcyq7O87?scene_id=62211704'},
    {img:'/funciton/function-vr-2.png',name:'装配式建筑',position:'展示内容：建筑、机电、装修',href:'https://720yun.com/t/53vkzb2q0iy?scene_id=85806809'},
    {img:'/funciton/function-vr-3.png',name:'类型：轨道交通及建筑',position:'展示内容：轨道交通机电',href:'https://720yun.com/t/46vkzmpeOiw?scene_id=83219464'},
    {img:'/funciton/function-vr-4.png',name:'类型：桥梁',position:'展示内容：桥梁',href:'https://720yun.com/t/edvkbbfy51h?scene_id=97430073'},
    {img:'/funciton/function-vr-5.png',name:'类型：隧道',position:'展示内容：道路及隧道',href:'https://720yun.com/t/3bvkbbfqzpy?scene_id=97467266'},
    {img:'/funciton/function-vr-6.png',name:'类型：城市道路及桥梁',position:'展示内容：道路及桥梁',href:'https://720yun.com/t/d2vkOb1yzre?scene_id=58824883'},
]
