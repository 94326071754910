import React, { useState } from "react";
import classNames from "classnames";

import IconLink from "./icon-link";
import IconLinkLarge from "./icon-link-large";

import {
    cover,
    projectCaseBox,
    byCenter,
    bottom,
    bottomA,
} from "./projectCase.module.css"
/**
 * 
 * @param {{
 * title:string,
 * postion:string,
 * type:string,
 * bgUrl:string,
 * hoverUrl?:string,
 * outsideLink?:string
 * }} param0 
 * @returns {JSX.Element}
 */
export default function ProjectCase({index, title, postion, type, bgUrl, hoverUrl, outsideLink }) {
    const a=()=>{
        console.log(index);
    }
    const [num,setNum]=useState(-1)
    return (
        <div className={projectCaseBox} onMouseOver={()=>{setNum(index);}} onMouseOut={()=>{setNum(-1)}}>
            <div style={{ position: "relative" }} >
                <img style={{verticalAlign: 'middle'}} width="100%" alt={title} src={bgUrl} />
                <a target={"_blank"} href={outsideLink} className={byCenter}>
                <IconLinkLarge count={index} num={num}  size={'8vw'} imagePath={hoverUrl}  />
                </a>
                <div className={cover} style={(index===num)?{display:'block'}:{display:'none'}}></div>
            </div>

            <div className={bottom}>
                <h3 onClick={a}>项目名称：{title}</h3>
                <h5>位置： {postion}</h5>
                <h5>类型： {type}</h5>
                <a target={"_blank"}  className={bottomA}
                    href={outsideLink}>
                     <IconLink count={index} num={num} size={'1.2em'} />
                </a>
              
            </div>
        </div>
    )
}